 /* eslint-disable */
import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from "../components/seo"
import Header from "../components/header"

import Img from 'gatsby-image'

import Footer from "../components/footer"




export default ({ data, pageContext: { node, locale, pagePath } }) => {

    let pageData = data.pageData.edges[0].node




    let {
        img_001_new,
        img_002_new,
        img_003_new,
        img_004_new,
        img_005_new,
    } = pageData.frontmatter

    




    let globalData = data.globalData.edges[0].node

    let page_title_local = globalData.frontmatter[locale + "_page_title_wooden_doors_prefix"] + " | " + globalData.frontmatter[locale + "_page_title_global_prefix"] + " " + globalData.frontmatter[locale + "_page_title_since_2001_prefix"]  
    let page_meta_description = pageData.frontmatter[locale + "_meta_desc"]
    let page_meta_keywords = pageData.frontmatter[locale + "_meta_keywords"]



    return (
        <>

            <SEO 
                description={page_meta_description}
                keywords={page_meta_keywords}
                pathname={pagePath}
                title={page_title_local}
            />

            <Header 
                locale={locale}
                pagePath={pagePath}
             />


            <div id="artdoor_home_carousel" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li key="homepage-carousel-item-01" data-target="#artdoor_home_carousel" data-slide-to="0" className="active"></li>
                            <li key="homepage-carousel-item-02" data-target="#artdoor_home_carousel" data-slide-to="1"></li>
                            <li key="homepage-carousel-item-03" data-target="#artdoor_home_carousel" data-slide-to="2"></li>
                            <li key="homepage-carousel-item-04" data-target="#artdoor_home_carousel" data-slide-to="3"></li>
                            <li key="homepage-carousel-item-05" data-target="#artdoor_home_carousel" data-slide-to="4"></li>
                        </ol>

                        <div id="homepage_carousel" className="carousel slide">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <Img alt={page_meta_description} className="homepage_carousel_img" objectFit="cover" fluid={img_001_new.childImageSharp.fluid} />
                                    <div id="artdoorCarouselCaption" className="carousel-caption  d-md-block">
                                        <h5 className="carouselMainTitle">{pageData.frontmatter[ `${locale}_A_SLIDE_TITLE` ]}</h5>
                                        <Link className="carouselMainLink" to={`${locale === "he" ? "" : locale}/${pageData.frontmatter.url_A}`}>{pageData.frontmatter[ `${locale}_A_SLIDE_CTA` ]}</Link>                                   
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <Img alt={page_meta_description} className="homepage_carousel_img" objectFit="cover" fluid={img_002_new.childImageSharp.fluid} />
                                    <div id="artdoorCarouselCaption" className="carousel-caption  d-md-block">
                                        <h5 className="carouselMainTitle">{pageData.frontmatter[ `${locale}_B_SLIDE_TITLE` ]}</h5>
                                        <Link className="carouselMainLink" to={`${locale === "he" ? "" : locale}/${pageData.frontmatter.url_B}`}>{pageData.frontmatter[ `${locale}_B_SLIDE_CTA` ]}</Link>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <Img alt={page_meta_description} className="homepage_carousel_img" objectFit="cover" fluid={img_003_new.childImageSharp.fluid} />
                                    <div id="artdoorCarouselCaption" className="carousel-caption  d-md-block">
                                        <h5 className="carouselMainTitle">{pageData.frontmatter[ `${locale}_C_SLIDE_TITLE` ]}</h5>
                                        <Link className="carouselMainLink" to={`${locale === "he" ? "" : locale}/${pageData.frontmatter.url_C}`}>{pageData.frontmatter[ `${locale}_C_SLIDE_CTA` ]}</Link>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <Img alt={page_meta_description} className="homepage_carousel_img" objectFit="cover" fluid={img_004_new.childImageSharp.fluid} />
                                    <div id="artdoorCarouselCaption" className="carousel-caption  d-md-block">
                                        <h5 className="carouselMainTitle">{pageData.frontmatter[ `${locale}_D_SLIDE_TITLE` ]}</h5>
                                        <Link className="carouselMainLink" to={`${locale === "he" ? "" : locale}/${pageData.frontmatter.url_D}`}>{pageData.frontmatter[ `${locale}_D_SLIDE_CTA` ]}</Link>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <Img alt={page_meta_description} className="homepage_carousel_img" objectFit="cover" fluid={img_005_new.childImageSharp.fluid} /> 
                                    <div id="artdoorCarouselCaption" className="carousel-caption  d-md-block">
                                        <h5 className="carouselMainTitle">{pageData.frontmatter[ `${locale}_E_SLIDE_TITLE` ]}</h5>
                                        <Link className="carouselMainLink" to={`${locale === "he" ? "" : locale}/${pageData.frontmatter.url_E}`}>{pageData.frontmatter[ `${locale}_E_SLIDE_CTA` ]}</Link>
                                    </div>
                                </div>

                                </div>
                                    <a className="carousel-control-prev" href="#artdoor_home_carousel" role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a className="carousel-control-next" href="#artdoor_home_carousel" role="button" data-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
            </div>


            <Footer 
                locale={locale}
                pagePath={pagePath}
             />

        </>
    
    )
}




export const imgQueryCarousel = graphql`query imgQueryCarousel {
    pageData:
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "homePageCarousel"}}}) {
      edges {
        node {
          frontmatter {
            metaType
            ru_meta_desc
            he_meta_desc
            fr_meta_desc
            en_meta_desc
            es_meta_desc
            pr_meta_desc
            ru_meta_keywords
            he_meta_keywords
            fr_meta_keywords
            en_meta_keywords
            es_meta_keywords
            pr_meta_keywords
            he_A_SLIDE_TITLE
            he_B_SLIDE_TITLE
            he_C_SLIDE_TITLE  
            he_D_SLIDE_TITLE  
            he_E_SLIDE_TITLE  
            fr_A_SLIDE_TITLE  
            fr_B_SLIDE_TITLE 
            fr_C_SLIDE_TITLE  
            fr_D_SLIDE_TITLE 
            fr_E_SLIDE_TITLE  
            en_A_SLIDE_TITLE 
            en_B_SLIDE_TITLE 
            en_C_SLIDE_TITLE 
            en_D_SLIDE_TITLE  
            en_E_SLIDE_TITLE  
            pr_A_SLIDE_TITLE  
            pr_B_SLIDE_TITLE  
            pr_C_SLIDE_TITLE   
            pr_D_SLIDE_TITLE 
            pr_E_SLIDE_TITLE  
            es_A_SLIDE_TITLE  
            es_B_SLIDE_TITLE   
            es_C_SLIDE_TITLE       
            es_D_SLIDE_TITLE  
            es_E_SLIDE_TITLE   
            ru_A_SLIDE_TITLE  
            ru_B_SLIDE_TITLE  
            ru_C_SLIDE_TITLE  
            ru_D_SLIDE_TITLE  
            ru_E_SLIDE_TITLE  
            he_A_SLIDE_CTA   
            he_B_SLIDE_CTA  
            he_C_SLIDE_CTA 
            he_D_SLIDE_CTA   
            he_E_SLIDE_CTA  
            fr_A_SLIDE_CTA  
            fr_B_SLIDE_CTA  
            fr_C_SLIDE_CTA  
            fr_D_SLIDE_CTA  
            fr_E_SLIDE_CTA  
            en_A_SLIDE_CTA   
            en_B_SLIDE_CTA 
            en_C_SLIDE_CTA  
            en_D_SLIDE_CTA  
            en_E_SLIDE_CTA  
            pr_A_SLIDE_CTA 
            pr_B_SLIDE_CTA 
            pr_C_SLIDE_CTA  
            pr_D_SLIDE_CTA  
            pr_E_SLIDE_CTA 
            es_A_SLIDE_CTA  
            es_B_SLIDE_CTA  
            es_C_SLIDE_CTA  
            es_D_SLIDE_CTA  
            es_E_SLIDE_CTA  
            ru_A_SLIDE_CTA  
            ru_B_SLIDE_CTA  
            ru_C_SLIDE_CTA
            ru_D_SLIDE_CTA   
            ru_E_SLIDE_CTA
            url_A
            url_B
            url_C
            url_D  
            url_E
            img_001_new {
                childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
            img_002_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_003_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_004_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_005_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_006_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_007_new {
                childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            img_001 {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            img_002 {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            img_005 {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            img_006 {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            img_007 {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            img_008 {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            img_009 {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
          }
        }
      }
    }
    globalData: 
    allMarkdownRemark(filter: {frontmatter: {type: {eq: "global_data"}}}) {
        edges {
            node {
                id
                frontmatter {
                    he_page_title_global_prefix
                    he_page_title_since_2001_prefix
                    he_page_title_wooden_doors_prefix
                    he_door_series
                    fr_page_title_global_prefix
                    fr_page_title_since_2001_prefix
                    fr_page_title_wooden_doors_prefix
                    fr_door_series
                    en_page_title_global_prefix
                    en_page_title_since_2001_prefix
                    en_page_title_wooden_doors_prefix
                    en_door_series
                    pr_page_title_global_prefix
                    pr_page_title_since_2001_prefix
                    pr_page_title_wooden_doors_prefix
                    pr_door_series
                    es_page_title_global_prefix
                    es_page_title_since_2001_prefix
                    es_page_title_wooden_doors_prefix
                    es_door_series
                    ru_page_title_global_prefix
                    ru_page_title_since_2001_prefix
                    ru_page_title_wooden_doors_prefix
                    ru_door_series
                }
            }
        }
    }
  }
  
`
